/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

Array.prototype.pushAll = function (array: Array<any>): void {
    this.push.apply(this, array);
};

Array.prototype.invert = function () {
    return this.slice().reverse();
};

String.prototype.indexOfCaseInsensitive = function (searchString: string, position?: number): number {
    return this.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase(), position);
};

String.prototype.containsCaseInsensitive = function (searchString: string): boolean {
    return this.indexOfCaseInsensitive(searchString) !== -1;
};

/**
 * A helper function that performs the common logic needed by both the `templateByName`
 * and `templateByIndex` methods.
 *
 * @param strings The string literals that were found in the template string.
 * @param keys The expressions that were found in the template string (i.e. ${...}).
 * @param values The set of values to substitute for the keys.
 * @returns The string that results from replacing the keys with the values.
 */
function templateHelper(strings: TemplateStringsArray, keys: (string | number)[], values: Object): string {
    const result = [strings[0]];
    keys.forEach((key, i) => result.push(values[key], strings[i + 1]));
    return result.join('');
}

String.templateByName = (strings: TemplateStringsArray, ...keys: string[]) => {
    return values => templateHelper(strings, keys, values);
};

String.templateByIndex = (strings: TemplateStringsArray, ...keys: number[]) => {
    return (...values: any[]) => templateHelper(strings, keys, values);
};
