// This must be loaded before anything else in order to simulate a full es2015 environment.
// See http://babeljs.io/docs/usage/polyfill/
import 'babel-polyfill';

// Custom polyfills.
// TODO: Look at solving this in a way other than modifying built-in prototypes.
import './app/polyfills/lib-extensions';

// Polyfills required by Angular 2.
require('zone.js/dist/zone');
import 'reflect-metadata';

// TypeScript helpers.
import 'ts-helpers';
